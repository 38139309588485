import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "List Categories", "create-button-text": "Add Category", "search-by": "categoryName", "ph-search-by": "Search by Category", "actions-width": 120, "has-delete": false, "has-filter-inactive": true, "row-key": "id" }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(" " + _vm._s(record.isInactive ? "Activate" : "Deactivate") + " ")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active mr-2", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "disabled": record.isInactive, "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1)];
  } }]) }, [_c("text-field", { key: "categoryName", attrs: { "data-index": "categoryName", "title": "Category", "sorter": true } }), _c("text-field", { key: "portfolioName", attrs: { "data-index": "portfolioName", "title": "Portfolio", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
render$1._withStripped = true;
const __vue2_script$1 = {
  name: "ListCategoryData",
  data() {
    return {};
  },
  computed: {}
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
__component__$1.options.__file = "src/views/CategoryData/ListCategoryData/ListCategoryData.vue";
var ListCategoryData = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-category-data" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "shipment-closure.category-data", "api-url": _vm.apiUrl, "resource-id-name": "id", "create-route": "/shipment-closure/category-data/create", "edit-route": "/shipment-closure/category-data/:id" } }, [_c("list-category-data")], 1), _c("resource", { attrs: { "name": "shipment-closure.portfolio-data", "api-url": _vm.apiUrl, "redirect-route": "/shipment-closure/category-data" } }), _c("router-view")], 1);
};
var staticRenderFns = [];
render._withStripped = true;
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListCategoryData
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "category-data",
          title: "Category",
          path: "/shipment-closure/category-data"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
__component__.options.__file = "src/views/CategoryData/ListCategoryData/index.vue";
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
